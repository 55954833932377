<template>
    <v-section class="h-full flex flex-col">
        <v-section>
            <v-section-heading>
                Conversations
            </v-section-heading>

            <v-section class="p-default bg-black bg-opacity-40">
                <v-form class="w-full" @submit.prevent="submitSearch">
                    <div class="flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4">
                        <v-form-input ref="searchQueryInput" :value="searchQuery" type="text" placeholder="Search ..." class="w-full" />
                        <v-button v-show="false" /> <!-- catch click on sibling when enter is pressed -->

                        <v-button v-show="searchQuery && searchQuery.length > 0" color="danger" @click="searchQuery = null">
                            <i class="fas fa-times" />
                            <span class="ml-2 sm:hidden">
                                Clear
                            </span>
                        </v-button>

                        <v-button v-show="false" type="submit" color="light">
                            <v-icon class="fa fa-search" />
                            <span class="ml-2 sm:hidden">
                                Search
                            </span>
                        </v-button>
                    </div>
                </v-form>
            </v-section>
        </v-section>

        <div class="h-full min-h-0 overflow-y-auto" @scroll="showMoreConversation">
            <template v-if="isLoading">
                <v-loader />
            </template>

            <template v-else-if="conversations.length === 0">
                <div class="w-full h-full flex flex-col items-center justify-center text-center">
                    <i class="fas fa-comments fa-2x mb-4 text-gray-300" />
                    <v-text>
                        No conversations found.
                    </v-text>
                </div>
            </template>

            <template v-else>
                <v-section-group>
                    <v-section>
                        <template v-for="(conversation, conversationIndex) in conversations">
                            <router-link :key="'conversation_' + conversationIndex" v-slot="{ isActive, href, navigate }" :to="{ name: 'conversations.show', params: { conversation: conversation.conversation_id } }" custom>
                                <a :href="href" :class="isActive ? 'bg-slate' : ''" class="block p-default hover:bg-slate group" aria-current="page" @click="navigate">
                                    <div class="flex justify-between space-x-3">
                                        <div class="min-w-0 flex-1">
                                            <p :class="isActive ? 'text-primary-500' : ''" class="text-sm font-medium text-gray-300 group-hover:text-primary-500 truncate">
                                                {{ conversation.user_name }}
                                            </p>
                                        </div>
                                        <time v-if="conversation.latest_message_id" class="flex-shrink-0 whitespace-nowrap text-sm text-gray-400">{{ conversation.latest_message_created_at | dateToHuman }}</time>
                                    </div>
                                    <div>
                                        <p class="mt-2 line-clamp-2 text-sm text-gray-500">
                                            <template v-if="conversation.latest_message_content">
                                                <template v-if="conversation.latest_message_type_id === 1">
                                                    {{ conversation.latest_message_content }}
                                                </template>

                                                <template v-else>
                                                    <span class="italic">
                                                        {{ conversation.latest_message_content }}
                                                    </span>
                                                </template>
                                            </template>

                                            <template v-else>
                                                <span class="italic">
                                                    Pending first message ...
                                                </span>
                                            </template>
                                        </p>
                                    </div>
                                </a>
                            </router-link>
                        </template>

                        <template v-if="showBottomLoader">
                            <v-loader style="height: 5rem;" />
                        </template>
                    </v-section>
                </v-section-group>
            </template>
        </div>
    </v-section>
</template>

<script>
import ConversationService from "@/services/modules/conversation-service";
import _ from "lodash";
import EchoMixin from "@/mixins/echo";

export default {
    mixins: [
        EchoMixin,
    ],
    props: {
        selectedConversation: {
            type: Object,
            required: false,
            default: () => null,
        },
    },
    data() {
        return {
            conversations: [],
            displayedConversations: [],
            conversationService: ConversationService,
            searchQuery: this.$route.query.searchQuery || null,
            fetchQuery: {
                searchQuery: null,
            },
            page: 2,
            totalConversations: 0,
            showBottomLoader: true,
        };
    },
    watch: {
        query: {
            handler(value) {
                // Set page to the query string value on initial page load.
                this.fetchQuery = {
                    ...this.fetchQuery,
                    ...value,
                };
            },
            deep: true,
            immediate: true,
        },
        fetchQuery: {
            handler(value) {
                this.$nextTick(() => {
                    if (!this.isLoading) {
                        this.toggleLoading();
                    }

                    this.replaceQueryStrings(value);

                    this.conversationService.index(value)
                        .then((response) => {
                            this.conversations = response.data.data;
                            this.totalConversations = response.data.meta.total;

                            // Add empty conversation that says "Pending first message.." if the selected conversation has no message and search is empty.
                            if (this.selectedConversation && !this.selectedConversation.latest_message_id && !value.searchQuery) {
                                this.addEmptyConversation(this.selectedConversation);
                            }
                        })
                        .finally(() => {
                            this.toggleLoading();
                        });
                });
            },
            deep: true,
            immediate: true,
        },
        searchQuery: {
            handler(value) {
                this.fetchQuery.searchQuery = value;
            },
            deep: true,
            immediate: true,
        },
        selectedConversation: {
            handler(value) {
                // Add empty conversation that says "Pending first message.." if the selected conversation has no message and search is empty.
                if (value && !value.latest_message_id && !this.searchQuery) {
                    this.addEmptyConversation(value);
                }
            },
            deep: true,
        },
    },
    created() {
        this.echo.private("Message-Channel")
            .listen("MessageCreated", (message) => this.onMessageCreated(message, false));

        if (this.$me.hasPermission("messages.private.read-all")) {
            this.echo.private("Private-Message-Channel")
                .listen("MessageCreated", (message) => this.onMessageCreated(message, true));
        }
    },
    beforeDestroy() {
        this.echo.leave("Message-Channel");
        this.echo.leave("Private-Message-Channel");
        this.echo.leaveChannel("Conversation-Channel");
        this.echo.leaveChannel("Message-Channel");

        if (this.$me.hasPermission("messages.private.read-all")) {
            this.echo.leaveChannel("Private-Message-Channel");
        }
    },
    methods: {
        /**
         * When a new message is created and received from Pusher.
         */
        onMessageCreated(message, isPrivateNote = false) {
            if (message.conversation_command_id || message.content.startsWith("!")) {
                return;
            }

            /**
             * Step 1 - Check to see if the conversation exists in the local list.
             */
            const conversationIndex = this.conversations.findIndex((conversation) => conversation.conversation_id === message.conversation_id);

            /**
             * Step 2 - If the conversation already exists, we just update certain parts of it.
             */
            if (conversationIndex !== -1) {
                this.conversationService.show(message.conversation_id)
                    .then((res) => {
                        this.conversations[conversationIndex].user_name = res.data.data.user.name;
                        this.conversations[conversationIndex].latest_message_id = message.message_id;
                        this.conversations[conversationIndex].latest_message_created_at = message.created_at;
                        this.conversations[conversationIndex].latest_message_content = (isPrivateNote ? "Private note .." : message.content);
                        this.conversations[conversationIndex].latest_message_type_id = message.message_type_id;
                        this.conversations = _.sortBy(this.conversations, ["latest_message_created_at", "DESC"]).reverse();
                    });
            }

            /**
             * Step 3 - If the conversation doesn't exist, we pull the list of conversations
             * and cherry-pick.
             */
            if (conversationIndex === -1) {
                this.conversationService.index(this.fetchQuery)
                    .then((response) => {
                        const result = response.data.data.find(({ conversation_id: conversationId }) => (conversationId === message.conversation_id));

                        if (!result) return;

                        this.conversationService.show(message.conversation_id)
                            .then((res) => {
                                this.conversations.push({
                                    ...res.data.data,
                                    user_name: res.data.data.user.name,
                                    latest_message_id: message.message_id,
                                    latest_message_created_at: message.created_at,
                                    latest_message_content: (isPrivateNote ? "Private note .." : message.content),
                                    latest_message_type_id: message.message_type_id,
                                });
                                this.conversations = _.sortBy(this.conversations, ["latest_message_created_at", "DESC"]).reverse();
                            });
                    });
            }
        },

        submitSearch() {
            let searchQuery = this.$refs.searchQueryInput.input;

            if (!searchQuery || searchQuery.toString().length < 1) {
                searchQuery = null;
            }

            this.searchQuery = searchQuery;
        },
        replaceQueryStrings(value) {
            const pickedValue = _.pickBy({
                ...this.$route.query,
                ...value,
            }, (item, key) => {
                if (key === "paginated") {
                    return false;
                }

                if (Array.isArray(item) && !item.length) {
                    return false;
                }

                if (typeof item === "boolean") {
                    return true;
                }

                return !!item;
            });

            if (!this.isEqualObject(this.$route.query, pickedValue)) {
                this.$router.replace({
                    query: {
                        ...pickedValue,
                    },
                });
            }
        },
        sortObjectKeys(object) {
            return _.sortBy(Object.keys(object)).map((key) => (object[key]));
        },
        isEqualObject(objectA = {}, objectB = {}) {
            objectA = this.stringifyObjectValues(objectA);
            objectB = this.stringifyObjectValues(objectB);

            return (JSON.stringify(this.sortObjectKeys(objectA)) === JSON.stringify(this.sortObjectKeys(objectB)));
        },
        stringifyObjectValues(object) {
            return _.transform(object, (result, value, key) => {
                result[key] = value;

                if (typeof value === "number" || typeof value === "boolean") {
                    result[key] = `${value}`;
                }

                if (Array.isArray(value)) {
                    const parsedArray = [];
                    value.forEach((item) => {
                        if (typeof item === "number" || typeof item === "boolean") {
                            item = `${item}`;
                        }

                        parsedArray.push(item);
                    });

                    result[key] = parsedArray;
                }
            }, {});
        },
        addEmptyConversation(conversation) {
            if (this.conversations.findIndex((conversationItem) => conversationItem.conversation_id === conversation.conversation_id) === -1) {
                this.conversations.push({
                    ...conversation,
                    user_name: conversation.user.name,
                    latest_message_id: null,
                    latest_message_created_at: null,
                    latest_message_content: null,
                });
            }
        },
        showMoreConversation(event) {
            if (event.target.scrollTop >= (event.target.scrollHeight - event.target.offsetHeight) && this.conversations.length !== this.totalConversations) {
                this.conversationService.index({ ...this.fetchQuery, page: this.page })
                    .then((response) => {
                        const unfilteredConversations = response.data.data;
                        const filteredConversations = [];

                        unfilteredConversations.forEach((conversation) => {
                            if (this.conversations.findIndex((conversationItem) => conversationItem.conversation_id === conversation.conversation_id) === -1) {
                                filteredConversations.push(conversation);
                            }
                        });

                        this.conversations = this.conversations.concat(filteredConversations);
                        this.page += 1;
                    });
            }

            if (this.conversations.length === this.totalConversations) {
                this.showBottomLoader = false;
            }
        },
    },
};
</script>
