<template>
    <v-main>
        <template v-if="$me.hasPermission('conversations.read.all') || $me.hasPermission('conversations.read.all.primary') || $me.hasPermission('conversations.read.all.assistant')" v-slot:sidebar>
            <v-conversation-list :selected-conversation="selectedConversation" />
        </template>

        <template v-slot:default="{ toggleSidebar }">
            <v-section class="h-full">
                <template v-if="$route.params.conversation">
                    <router-view :toggle-sidebar="toggleSidebar" :conversation-id="$route.params.conversation" @selected-conversation-loaded="setSelectConversation" />
                </template>

                <template v-else>
                    <div class="w-full h-full flex flex-col items-center justify-center text-center">
                        <i class="fas fa-comments fa-2x mb-4 text-gray-300" />
                        <template v-if="$me.hasPermission('conversations.read.all') || $me.hasPermission('conversations.read.all.primary') || $me.hasPermission('conversations.read.all.assistant')">
                            <v-text class="hidden lg:block">
                                Choose a conversation on the left side.
                            </v-text>
                            <div class="block lg:hidden">
                                <v-button color="primary" @click.stop="toggleSidebar">
                                    Choose Conversation
                                </v-button>
                            </div>
                        </template>
                        <template v-else>
                            <template v-if="!$me.getUser().conversation">
                                <v-button color="primary" @click="createConversation($me.getUser().user_id)">
                                    Start Conversation
                                </v-button>
                            </template>
                        </template>
                    </div>
                </template>
            </v-section>
        </template>
    </v-main>
</template>
<script>
import ConversationList from "@/components/application/conversation/conversation-list.vue";
import Main from "@/layouts/panel/main.vue";
import ConversationService from "@/services/modules/conversation-service";

export default {
    components: {
        "v-main": Main,
        "v-conversation-list": ConversationList,
    },
    data() {
        return {
            conversationService: ConversationService,
            selectedConversation: null,
        };
    },
    created() {
        this.redirectToUserConversation();
    },
    methods: {
        createConversation(userId) {
            this.conversationService.store({ user_id: userId })
                .catch((error) => {
                    alert(error.response.data.message);
                }).then((response) => {
                    this.$router.push({ name: "conversations.show", params: { conversation: response.data.data.conversation_id } });
                });
        },
        redirectToUserConversation() {
            if (this.$me.getUser().conversation) {
                if (this.$route.name !== "conversations.show") {
                    this.$router.push({ name: "conversations.show", params: { conversation: this.$me.getUser().conversation.conversation_id } });
                }
            } else {
                this.$me.fetchUser()
                    .then((response) => {
                        const userConversation = response.data.conversation;

                        if (userConversation && this.$route.name !== "conversations.show") {
                            this.$router.push({ name: "conversations.show", params: { conversation: userConversation.conversation_id } });
                        }
                    })
                    .catch((error) => {
                        console.error("error", error);
                    });
            }
        },
        setSelectConversation(conversation) {
            this.selectedConversation = conversation;
        },
    },
};
</script>
